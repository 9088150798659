<template>
    <transition name="search" appear>
        <div class="w-full mobile-search" v-show="visible" :class="{'expandSize': showForm || expanded}" ref="searchForm">
            <div v-show="!expended" class="mx-auto flex border-2 border-32838C px-2 py-1.5 rounded-49px items-center justify-center mt-0 w-[calc(100vw_-_100px)] bg-EFFDFF">
                <div>
                    <button :title="initSearchValues.location.name" type="button" class="p-2 bg-transparent border-none outline-none text-1B3B3F text-sm" @click.stop="showForm = true; setHeaderClass();">
                        <span class="line-clamp-1 w-full">{{ whereLabel }}</span>
                    </button>
                </div>
                <div class="mx-2 h-30px w-1px bg-32838C"></div>
                <div>
                    <button type="button" class="whitespace-nowrap p-2 bg-transparent border-none outline-none text-1B3B3F text-sm" @click.stop="showForm = true; setHeaderClass();">
                        {{ whenLabel }}
                    </button>
                </div>
                <div>
                    <button type="button" class="p-2 bg-transparent border-none outline-none" @click.stop="showForm = true; setHeaderClass()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 15 17" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9167 6.75793C10.9167 9.05474 9.05474 10.9167 6.75793 10.9167C4.46113 10.9167 2.59921 9.05474 2.59921 6.75793C2.59921 4.46113 4.46113 2.59921 6.75793 2.59921C9.05474 2.59921 10.9167 4.46113 10.9167 6.75793ZM9.83425 12.7767C8.91152 13.2493 7.86587 13.5159 6.75793 13.5159C3.02563 13.5159 0 10.4902 0 6.75793C0 3.02563 3.02563 0 6.75793 0C10.4902 0 13.5159 3.02563 13.5159 6.75793C13.5159 8.44319 12.899 9.98437 11.8787 11.168L14.5031 14.2297C14.9702 14.7747 14.9071 15.5951 14.3621 16.0622C13.8171 16.5293 12.9967 16.4662 12.5296 15.9212L9.83425 12.7767Z" fill="#32838C"/>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </transition>
    <Teleport to="body">
    <transition name="location-results" mode="out-in" appear v-show="showForm">
        <div class="w-full fixed top-0 bottom-0 left-0 h-[100dvh] py-5 overflow-auto z-1000 flex flex-col" :class="bgForm">
            <div class="flex relative justify-between px-4">
                <button type="button" class="font-semibold self-end inline-flex items-center pl-2 pr-2 py-2 group/contact border-2 rounded-30px text-black border-none bg-black bg-opacity-5 justify-center text-base" @click="searchNav"> 
                    <div class="m-0 group-hover/contact:transform group-hover/contact:-translate-x-1 duration-300 ease-in-out transition-all text-black/50">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L9.41421 12L15.7071 18.2929C16.0976 18.6834 16.0976 19.3166 15.7071 19.7071C15.3166 20.0976 14.6834 20.0976 14.2929 19.7071L7.29289 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.29289 11.2929L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289Z" fill="currentColor"></path> </g></svg>
                    </div>
                </button>
                <svg class="h-10 -mr-[14px]" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg" width="70.085854" height="99.982513" viewBox="0 0 70.085854 99.982513" fill="none" version="1.1" id="svg3" sodipodi:docname="Group 23.svg" inkscape:version="1.3 (0e150ed, 2023-07-21)"><path d="M 29.13391,74.522624 C 13.06649,74.522624 0,61.310694 0,45.074955 0,28.839157 13.071058,15.622822 29.13391,15.622822 c 16.062852,0 29.138425,13.21171 29.138425,29.452133 0,16.240469 -13.071006,29.447669 -29.138425,29.447669 z m 0,-45.789474 c -8.911644,0 -16.163186,7.329619 -16.163186,16.341805 0,9.012347 7.251542,16.337069 16.163186,16.337069 8.911643,0 16.167753,-7.329447 16.167753,-16.337069 0,-9.007569 -7.251542,-16.341805 -16.167753,-16.341805 z" fill="#32838c" id="path1" style="stroke-width: 0.525033;"></path><path d="m 70.085571,7.6892622 c 0.0362,4.2732938 -3.406941,7.6799688 -7.72166,7.6477318 C 58.141076,15.309324 54.875373,11.990225 54.852797,7.7030702 54.834417,3.4021272 58.246083,-0.01836579 62.532976,7.4207615e-5 66.614577,0.01851421 70.053541,3.5219832 70.085571,7.6892622 Z" fill="#32838c" id="path2" style="stroke-width: 0.525033;"></path><path d="m 30.520731,99.982514 c -7.917441,0 -15.67522,-2.17101 -22.429661,-6.27834 -3.069357,-1.86702 -4.059029,-5.89612 -2.216478,-8.99854 1.847065,-3.10241 5.833166,-4.1026 8.902508,-2.24031 4.738578,2.88085 10.179544,4.4024 15.743631,4.4024 5.564087,0 10.941158,-1.50265 15.656949,-4.352 3.073963,-1.85756 7.055391,-0.839 8.89353,2.26815 1.837615,3.10713 0.830077,7.13151 -2.24399,8.98908 -6.722519,4.05693 -14.439241,6.20484 -22.306489,6.20484 z" fill="#32838c" id="path3" style="stroke-width: 0.525033;"></path></svg>
            </div>
            <div class="w-full flex flex-col  px-5 py-3 mt-0 mb-10"  ref="searchInput" v-show="showInputSearch">
                <h3 class="pt-5 text-center font-semibold text-xl text-164B51 mb-3">Where are you going?</h3>
                <div class="w-full mb-0">
                    <input ref="searchInputField" autofocus type="text" class="w-full rounded-49px text-ellipsis placeholder:text-center border-2 border-32838C bg-transparent py-3 px-4 text-1B3B3F text-base focus-visible:outline-none" v-model="initSearchValues.location.name" @input="getLocationsVariants" placeholder="Type here to search..."/>
                </div>
                <transition name="location-results" mode="out-in" appear>
                    <div v-if="initSearchValues.showLocations" class="top-full bg-EEFAFC/50 py-5 px-1 rounded-20px border border-none max-w-579px w-full max-h-full overflow-auto">
                        <div v-for="(location, index) in initSearchValues.locations" :key="index">
                            <div class="w-full flex items-center p-2 hover:bg-[#efefef] group/location transition-all duration-300 ease-in-out rounded-10px cursor-pointer" @click="setLocation(location)">
                                <div class="flex flex-col px-2">
                                    <h5 class="text-base text-black font-bold">{{ location.name }}</h5>
                                    <p class="m-0 text-B1B1B1 text-xs group-hover/location:text-[#666666]">{{ location.path }}</p>
                                </div>
                                <div class="hidden ml-auto">
                                    <svg width="32" height="28" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.7187 7.29289C13.0792 6.93241 13.6464 6.90468 14.0387 7.2097L14.1329 7.29289L20.1329 13.2929C20.4934 13.6534 20.5211 14.2206 20.2161 14.6129L20.1329 14.7071L14.1329 20.7071C13.7424 21.0976 13.1092 21.0976 12.7187 20.7071C12.3582 20.3466 12.3305 19.7794 12.6355 19.3871L12.7187 19.2929L18.0108 14L12.7187 8.70711C12.3582 8.34662 12.3305 7.77939 12.6355 7.3871L12.7187 7.29289Z" fill="#343724"/>
                                    <rect x="1.41602" y="0.5" width="30.0199" height="27" rx="13.5" stroke="#32838C"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
            <transition name="location-results" mode="out-in" appear>
                <div class="w-full mt-0 p-5 mobile-search-calendar" v-show="showDates && showInputSearch === false">
                    <h3 class="pt-0 text-center font-semibold text-xl text-164B51 mb-3">Choose traveling dates</h3>
                    <VueDatePicker multi-calendars :teleport="false" ref="datepicker" auto-apply v-model="initSearchValues.dates"  range :partial-range="false" :enable-time-picker="false" placeholder="When?" :min-date="new Date()" @update:model-value="nextStep = 2; updateSearchState" :inline="true"></VueDatePicker>
                </div>
            </transition>
            <transition name="location-results" mode="out-in" appear>
                <div class="flex w-full flex-col p-5" ref="searchBlock" v-show="showActions && showInputSearch === false">
                    <h3 class="pt-5 text-center font-semibold text-xl text-164B51 mb-1"><span class="sm:pl-12">What are you looking for in</span><span class="block text-CAE43E">{{ this.initSearchValues.location.name }}?</span></h3>
                    <p class="text-lg font-semibold text-center text-gray-400 mb-3">{{ whenLabel }}</p>
                    <div class=" mt-2 flex justify-around w-full flex-col sm:flex-row gap-3 sm:gap-0">
                        <button type="button" class="px-5 py-2 border-2 rounded-30px text-32838C border-32838C justify-center text-base font-semibold bg-none hover:bg-32838C hover:text-white" :class="{'bg-32838Cw !text-whitew': initSearchValues.askGuide}" @click.stop="initSearchValues.askGuide=true; initSearchValues.localInfo = false; initSearchValues.experiences = false;  goToSearchResults()">Local Guide</button>
                        <button type="button" class="px-5 py-2 border-2 rounded-30px text-32838C border-32838C justify-center text-base font-semibold bg-none hover:bg-32838C hover:text-white" :class="{'bg-32838Cw !text-whitew': initSearchValues.localInfo}" @click.stop="initSearchValues.localInfo = true; initSearchValues.askGuide = false; initSearchValues.experiences = false;  goToSearchResults()">Local Recommendations</button>
                        <button type="button" class="px-5 py-2 border-2 rounded-30px text-32838C border-32838C justify-center text-base font-semibold bg-none hover:bg-32838C hover:text-white" :class="{'bg-32838Cw !text-whitew': initSearchValues.experiences}" @click.stop="initSearchValues.experiences = true; initSearchValues.askGuide = false; initSearchValues.localInfo = false;  goToSearchResults()">Local Experiences</button>
                    </div>
                </div>
            </transition>
        </div>
    </transition>
</Teleport>
</template>
<style scoped>
.search-enter-active,
.search-leave-active {
  transition: opacity 0.5s ease;
}

.search-enter-from,
.search-leave-to {
  opacity: 0;
}
.locations-left-enter-from {
  opacity: 0;
}
.locations-left-enter-active {
  transition: all 0.3s ease-out;
}
.locations-left-leave-to {
  opacity: 0;
}
.locations-left-leave-active {
  transition: all 0.3s ease-in;
}
.locations-right-enter-from {
  opacity: 0;
}
.locations-right-enter-active {
  transition: all 0.3s ease-out;
}
.locations-right-leave-to {
  opacity: 0;
}
.locations-right-leave-active {
  transition: all 0.3s ease-in;
}
</style>
<script>
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import moment, { RFC_2822 } from 'moment'
import { mapGetters, mapActions, mapState} from 'vuex'

export default {
   
    expose: ['goToSearchResults'],
    metaInfo() {
        return {

        }
    },
    beforeMount() {
        this.setSearchType()
        this.$store.dispatch('detectDevice');
    },
    watch:{
        $route (to, from){
            this.showForm = false
            this.setSearchType()
        },
    },
    props: {
        expanded: {
            type: Boolean,
            default: false,
        },
        visible: {
            type: Boolean,
            default: false,
        },
        layout:{
            type:String,
            default:'default'
        },
        bgForm:{
            format: String,
            default:'bg-white'
        },
        close: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            transitionName:'locations-left',
            showInputSearch:true,
            nextStep:0,
        }
    },
    computed:{
        ...mapGetters(['expandSearch','getSearch']),
        initSearchValues(){ 
            return this.getSearch
        },
        whereLabel(){
            return this.initSearchValues.location.name !== "" ? this.initSearchValues.location.name : 'Where are you going?'
        },
        whenLabel(){
            return this.initSearchValues.dates ? this.formatLabelDates() : 'When?'
        },
        showForm(){
            return this.expandSearch
        },
        showDates(){
            let result = false
            if(this.initSearchValues.location.id && this.nextStep === 1){
                result = true
            }
            return result
        },
        showActions(){
            let result = false
            if(this.initSearchValues.dates && this.initSearchValues.location.id && this.nextStep === 2){
                result = true
            }
            return result
        },
    },
    methods: {
        ...mapActions(['expandSearch','getLocations']),
        ...mapGetters(['getSearch']),

        resetFormSize(event){
            this.$store.dispatch('expandSearch', false)
        },
        middleware (event) {
            return event.target.className !== 'expandSize'
        },
        setHeaderClass(){
            this.$store.dispatch('expandSearch', true)
            if(document.body.classList.contains('open-modal')){
                document.body.classList.remove('open-modal')
            } else {
                document.body.classList.add('open-modal')
            }
            setTimeout(() => {
                this.$refs.searchInputField.focus()
            }, 300)
            
        },
        minifySearch(){
            this.$store.dispatch('expandSearch', false)
            this.initSearchValues.showLocations = false
            this.$refs.datepicker.closeMenu()
            document.body.classList.remove('open-modal')
            this.showInputSearch = true
            this.nextStep = 0
        },
        openCalendar(){
            setTimeout(() => {
                this.$refs.datepicker.openMenu();
            }, "400");

            
        },
        getLocationsVariants(){
            const txt = this.initSearchValues.location.name.trim();
            if(txt.length === 0){
                delete this.initSearchValues.location.id
                this.initSearchValues.showLocations = false
            }
            if (txt.length > 3){
                this.initSearchValues.showLocations = true
                this.getLocations({t: txt}).then((locations) => {
                    this.initSearchValues.locations = locations
                    
                    if(this.initSearchValues.showLocations && this.initSearchValues.locations.length){

                    }
                    
                })
            }
        },
        clickOutsideLocations(){

            this.hideLocations()
        },
        hideLocations(){
            this.initSearchValues.showLocations = false
        },
        setLocation(location){
            this.initSearchValues.location = location
            this.updateSearchState()
            this.hideLocations()
            this.showInputSearch = false
            this.nextStep = 1
        },
        goToSearchResults(){
            if(this.initSearchValues.location.id && this.initSearchValues){
                const startDate = moment(this.initSearchValues.dates[0]).format("YYYY-MM-DD")
                const endDate = moment(this.initSearchValues.dates[1]).format("YYYY-MM-DD")
                document.body.classList.remove('open-modal')
                this.showInputSearch = true
                this.nextStep = 0
                this.updateSearchState()
                if(this.initSearchValues.askGuide){
                    this.$router.push({ name: 'SearchGuides', query: { lid: this.initSearchValues.location.id, start_date: startDate, end_date: endDate}})
                }
                if(this.initSearchValues.localInfo){
                    this.$router.push({ name: 'SearchRecommendations', query: { lid: this.initSearchValues.location.id, start_date: startDate, end_date: endDate}})
                }
                if(this.initSearchValues.experiences){
                    this.$router.push({ name: 'SearchExperiences', query: { lid: this.initSearchValues.location.id, start_date: startDate, end_date: endDate}})
                }
            }
        },
        setSearchType(){
            const routerName = this.$route.name
            if(routerName === 'SearchGuides'){
                this.initSearchValues.askGuide = true
                this.initSearchValues.localInfo = false
                this.initSearchValues.experiences = false
            }
            if(routerName === 'SearchRecommendations'){
                this.initSearchValues.askGuide = false
                this.initSearchValues.localInfo = true
                this.initSearchValues.experiences = false
            }
            if(routerName === 'SearchExperiences' ){
                this.initSearchValues.askGuide = false
                this.initSearchValues.localInfo = false 
                this.initSearchValues.experiences = true
            }
        },
        resetExtend(){
            this.expanded = false
        },
        formatLabelDates(){
            let startDate = moment(this.initSearchValues.dates[0]).format('MMM D')
            let endDate = moment(this.initSearchValues.dates[1]).format('MMM D')
            if(moment(this.initSearchValues.dates[0]).isSame(this.initSearchValues.dates[1], 'month')){
                startDate = moment(this.initSearchValues.dates[0]).format('MMM D');
                endDate = moment(this.initSearchValues.dates[1]).format('D');
            }
            const output = `${startDate} - ${endDate}`
            return output
        },
        updateSearchState(){
            this.$store.commit('setSearch', this.initSearchValues)
        },
        searchNav(){
            switch (this.nextStep) {
                case 1:
                    this.nextStep = 0
                    this.showInputSearch = true
                    break
                case 2:
                    this.nextStep = 1
                    break
                default:
                    this.minifySearch()
            }

        },
    },
    components: {
        VueDatePicker
    },
    beforeUnmount(){
        document.body.classList.remove('open-modal')
    },
}

</script>